body {
    font-family: Arial, sans-serif;
    background-image: url('./Quiz-app.png'); /* Relative path from the public root */
    background-size: cover; /* Cover the entire window */
    background-position: center; /* Center the image */
    background-attachment: fixed; /* Keep the background fixed during scrolling */
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Take the full height of the window */
    overflow: hidden; /* Prevent content overflow */
}

.App {
    text-align: center; /* Center text and inline elements */
    padding: 20px; /* Padding for content */
    width: 85%; /* Increased width to 85% */
    max-width: 420px; /* Increased max width for better comfort */
    max-height: 90vh; /* Limit height to avoid overflow issues */
    background-color: rgba(255, 255, 255, 0.9); /* Slightly more opaque background */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation effect */
    margin: auto; /* Center the container */
    overflow-y: auto; /* Enable scrolling if content exceeds height */
    box-sizing: border-box; /* Ensure padding is included in width/height calculations */
}

h1 {
    color: #4b0082; /* Dark indigo for titles */
    font-size: 2em; /* Smaller font size */
    margin-bottom: 10px; /* Reduced margin for tighter layout */
}

h2 {
    color: #5e2f8c; /* Lighter violet for subtitles */
    font-size: 1.5em; /* Slightly smaller font */
    margin-bottom: 10px;
}

button {
    background-color: #8e44ad; /* Vibrant violet */
    color: white;
    padding: 10px 15px; /* Adjusted padding for smaller buttons */
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    display: inline-block; /* Ensure buttons do not stretch unexpectedly */
}

button:hover {
    background-color: #732d91; /* Darker violet on hover */
}

button:disabled {
    background-color: #bdc3c7; /* Light gray for disabled buttons */
    cursor: not-allowed; /* Not-allowed cursor for disabled state */
}

select {
    padding: 8px; /* Reduced padding */
    margin: 10px 0;
    font-size: 1em;
    border: 2px solid #bdc3c7; /* Light gray border */
    border-radius: 5px;
    box-sizing: border-box; /* Ensure select box fits inside the container */
}

label {
    font-size: 1.1em; /* Slightly smaller font size */
    color: #4b0082; /* Dark indigo for labels */
    display: block;
    margin: 8px 0;
}

input[type="radio"] {
    margin-right: 8px; /* Reduced margin between radio button and label */
}

div {
    margin-bottom: 15px; /* Reduced spacing between div elements */
}

.StartScreen, .ResultScreen {
    padding: 15px; /* Reduced padding */
    background-color: rgba(255, 255, 255, 0.9); /* Slightly more opaque background for cards */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for better design */
    width: 100%; /* Ensure full width of the App */
    text-align: center;
    box-sizing: border-box; /* Avoid unexpected overflow due to padding */
}

.logo {
    max-width: 80px; /* Reduced logo size */
    margin: 15px auto; /* Center logo with reduced margin */
    display: block;
}

.ResultScreen button {
    margin-top: 15px; /* Adjust space above buttons in the result screen */
}